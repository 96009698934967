<template>
  <div>
    <div
      class="relative flex flew-row w-full text-dark-500 items-center disabled:opacity-50 justify-between font-primary text-sm transition-colors border-[1px] rounded"
      :class="{
        'border-error-500': props.error != '' && !props.disabled,
        'border-neutral-gray focus-within:border-primary-400': props.error == '' && !props.disabled,
      }"
    >
      <input
        :id="props.id"
        :name="props.id"
        :placeholder="props.placeholder"
        class="relative order-2 w-full p-3 text-sm focus:outline-none"
        :type="isPasswordVisible ? 'text' : type"
        v-model="valueModel"
        :autocomplete="props.autocomplete"
        :required="props.required"
        :disabled="props.disabled"
        @focus="
          (event) => {
            event.target.select();
            $emit('focus');
          }
        "
        @blur="
          () => {
            $emit('blur');
          }
        "
      />
      <label
        v-if="props.label != ''"
        :for="props.id"
        class="absolute first-letter:capitalize z-50 rounded-full px-1 transition-all bg-[#FFFFFF] ml-2 select-none order-3"
        :class="{
          '-mt-10 bg-opacity-100 text-xs': valueModel != '' || props.labelOnTop,
          'bg-opacity-0': valueModel == '' || props.labelOnTop,
          'text-neutral-gray': props.disabled,
          '!text-error-500': props.error != '' && !props.disabled,
          'text-gray-500': props.error == '' && !props.disabled,
          'ml-10':
            props.icon.position == 'left' &&
            (valueModel == '' || valueModel == null || valueModel == undefined),
        }"
      >
        {{ label }} {{ props.required ? "*" : "" }}
      </label>

      <Icon
        class="relative right-0 select-none"
        :class="{
          'cursor-pointer': props.icon.clickable,
          'cursor-default': !props.icon.clickable,
          '!text-neutral-gray': props.disabled,
          '!text-error-500': props.error != '' && !props.disabled,
          '!text-primary-500': !props.disabled && props.error == '',
          'order-1 ml-3': props.icon.position == 'left',
          'order-4 mr-3': props.icon.position == 'right',
        }"
        :icon="props.icon.name"
        :size="props.icon.size"
        @click="props.icon.click"
      />
    </div>
    <p v-show="props.error" class="first-letter:capitalize text-sm text-right !text-error-500">
      {{ props.error }}
    </p>
  </div>
</template>

<script setup>
// ✅ Vue imports
import { ref } from "vue";
// 📦 External packages imports

// 👨‍💻 Internal packages imports
import Icon from "@/Redesign/Components/Assets/Icon.vue";

// 1️⃣ Props, Emits, ...
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: String,
    default: "off",
  },
  labelOnTop: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    default: "",
  },
  icon: {
    type: Object,
    default: () => ({
      name: "",
      size: 20,
      position: "right",
      clickable: false,
      click: () => {},
    }),
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const valueModel = defineModel("modelValue", {
  type: String,
  default: "",
});

const emits = defineEmits(["focus", "blur"]);

// 2️⃣ General properties, methods, ...
const isPasswordVisible = ref(false);

// 3️⃣ Functions

// 4️⃣ Computed properties

// 5️⃣ Lifecycle hooks

// 6️⃣ Watchers

// 7️⃣ Expose
</script>

<style lang="scss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-previewed,
input:-internal-autofill-previewed:hover,
input:-internal-autofill-previewed:focus,
input:-internal-autofill-previewed:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}
</style>
